import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02509737"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "workshop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_workshops_communication = _resolveComponent("workshops-communication")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_header, {
      title: _ctx.page?.title
    }, null, 8, ["title"]),
    _createVNode(_component_workshops_communication)
  ]))
}