
import { defineComponent } from "vue";
import WorkshopsCommunication from "@/modules/workshops/Communication.vue";
import PageHeader from "@/common/PageHeader.vue";
import { contentsService, StaticContent } from "@/services/contents.service";

export default defineComponent({
  name: "Workshop",
  components: {PageHeader, WorkshopsCommunication },
  data() {
    return {
      page: undefined as StaticContent | undefined
    };
  },
  mounted() {
    contentsService.findBySlug("workshops").then((page) => {
      this.page = page as StaticContent;
    });
  }
});
