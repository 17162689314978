
import { defineComponent } from "vue";
import { paginatorService } from "@/services/paginator.service";

export default defineComponent({
  name: "WorkshopsCommunication",
  data() {
    return {
      list: [] as any[] | undefined,
      toggle: {},
    };
  },
  mounted() {
    paginatorService.load('workshops', 'asc').then((list) => {
      this.list = list;
    });
  },
});
